<template>
  <v-container fluid>
    <PageHeader showBackButton :isLoading="$store.getters.isLoading"> </PageHeader>
    <v-form ref="dataForm" @submit.prevent="save">
      <v-card class="mb-8">
        <v-card-text>
          <v-card-title class="page-title">用戶資料</v-card-title>
          <v-row class="ma-0">
            <v-col cols="12">
              <form-button-upload
                :fileValue.sync="formData.user_img"
                label="用戶圖片"
                ref="uploadImage"
                inputRef="imgFile"
              />
            </v-col>

            <v-col cols="12" md="6">
              <form-select label="狀態" :fieldValue.sync="formData.status" :options="$statusOptions" required />
            </v-col>

            <v-col cols="12" md="6">
              <form-input label="用戶名稱" :fieldValue.sync="formData.name" required />
            </v-col>

            <v-col cols="12" md="6">
              <form-select
                label="用戶類別"
                :fieldValue.sync="formData.user_type"
                :options="$userTypeOptions"
                required
                :readonly="id !== null"
              />
            </v-col>

            <v-col cols="12" md="6">
              <form-input label="電郵地址" :fieldValue.sync="formData.email" required type="email" />
            </v-col>

            <v-col cols="12" md="6">
              <form-input label="聯絡電話" :fieldValue.sync="formData.phone" required type="tel" />
            </v-col>

            <template v-if="formData.user_type === 'student'">
              <v-col cols="12" md="6">
                <form-select
                  label="用戶組別"
                  :fieldValue.sync="formData.student_group"
                  :options="studentGroupOptions"
                  :required="formData.user_type === 'student'"
                />
              </v-col>
              <v-col cols="12" md="6" v-if="id !== null">
                <form-input label="推薦碼" :fieldValue.sync="formData.referral_code" readonly />
              </v-col>
            </template>

            <template v-if="formData.user_type === 'tutor'">
              <v-col cols="12" md="6">
                <form-select
                  label="可教授課堂類型"
                  :fieldValue.sync="formData.lesson_category"
                  :options="$lessonCategoryOptions"
                  :required="formData.user_type === 'tutor'"
                />
              </v-col>
              <v-col cols="12" md="6">
                <form-select
                  label="可教授科目"
                  :fieldValue.sync="formData.subject"
                  :options="$subjectOptions"
                  :required="formData.user_type === 'tutor'"
                  isMultiple
                />
              </v-col>
              <v-col cols="12" md="6">
                <form-select
                  label="學歷"
                  :fieldValue.sync="formData.qualification"
                  :options="$qualificationOptions"
                  :required="formData.user_type === 'tutor'"
                />
              </v-col>
              <v-col cols="12" md="6">
                <form-select
                  label="性別"
                  :fieldValue.sync="formData.gender"
                  :options="$genderOptions"
                  :required="formData.user_type === 'tutor'"
                />
              </v-col>
              <v-col cols="12" md="6">
                <form-select
                  label="年齡層"
                  :fieldValue.sync="formData.age"
                  :options="$ageOptions"
                  :required="formData.user_type === 'tutor'"
                />
              </v-col>
              <v-col cols="12" md="6">
                <form-select
                  label="教學經驗"
                  :fieldValue.sync="formData.experience"
                  :options="$experienceOptions"
                  :required="formData.user_type === 'tutor'"
                />
              </v-col>
              <v-col cols="12" md="6">
                <form-select
                  label="審核狀態"
                  :fieldValue.sync="formData.admin_verified"
                  :options="verifyOptions"
                  :required="formData.user_type === 'tutor'"
                />
              </v-col>
            </template>
          </v-row>
        </v-card-text>
      </v-card>

      <v-btn depressed color="primary accent-4" class="white--text" type="submit" :loading="$store.getters.isLoading">
        儲存
      </v-btn>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import FormInput from '@/components/form/FormInput.vue'
import FormSelect from '@/components/form/FormSelect.vue'
import FormButtonUpload from '@/components/form/FormButtonUpload.vue'

export default {
  name: 'UserDetail',
  components: {
    FormInput,
    FormSelect,
    FormButtonUpload,
  },
  computed: {
    id() {
      const id = parseInt(this.$route.params.id)
      const userType = this.$route.params.type
      if (!isNaN(id) && userType) {
        return id
      }

      return null
    },
    userType() {
      return this.$route.params.type
    },
  },
  data: () => ({
    formData: {
      user_img: '',
      status: 'active',
      name: '',
      user_type: '',
      email: '',
      phone: '',

      // --- student
      student_group: null,
      referral_code: '',

      // --- tutor
      lesson_category: '',
      subject: [],
      qualification: '',
      gender: '',
      age: '',
      experience: '',
      admin_verified: null,
    },

    studentGroupOptions: [],

    verifyOptions: [
      { text: '已批核', value: true },
      { text: '待批核', value: false },
    ],
  }),

  methods: {
    ...mapActions(['setAlert']),
    async getDetail() {
      try {
        const user = this.getCurrentUserData()
        let data = null;
        if (this.userType === 'student') {
          data = await this.$Fetcher.GetStudentById(this.id, user.id, user.token)
        } else if (this.userType === 'tutor') {
          data = await this.$Fetcher.GetTutorById(this.id, user.id, user.token)
        } else {
          throw null;
        }
   
        this.formData.user_type = this.userType;
        for (const key in this.formData) {
          if (this.$validate.DataValid(data[key])) {
            this.$set(this.formData, key, data[key])
          }
        }
      } catch (err) {
        this.$common.error(err)
        if (err) {
          this.setAlert({
            type: 'error',
            message: err,
            redirect: 'UserList',
          })
        } else {
          this.setAlert({
            message: 'noData',
            redirect: 'UserList',
          })
        }
      } finally {
        this.$store.dispatch('setInitLoading', false)
      }
    },

    async getStudentGroupOptions() {
      try {
        const user = this.getCurrentUserData()
        const { data } = await this.$Fetcher.GetStudentGroups({}, user.id, user.token);
        this.studentGroupOptions = data.map(item => { return { value: item.id, text: item.name }})
      } catch {}
    },

    async save() {
      if (this.$store.getters.isLoading) {
        this.$store.dispatch('toggleSnack', {
          message: 'processing',
        })
        return
      }

      const valid = await this.$refs['dataForm'].validate()
      if (!valid) {
        this.$store.dispatch('toggleSnack', {
          message: 'required',
        })
        return
      }
      this.$store.dispatch('setLoading', true)
      let payload = {
        user_img: this.formData.user_img,
        status: this.formData.status,
        name: this.formData.name,
        email: this.formData.email,
        phone: this.formData.phone,
      }

      if (this.formData.user_type === 'student') {
        payload['student_group'] = this.formData.student_group
        // payload['referral_code'] = this.formData.referral_code
      } else if (this.formData.user_type === 'tutor') {
        payload['lesson_category'] = this.formData.lesson_category
        payload['subject'] = this.formData.subject
        payload['qualification'] = this.formData.qualification
        payload['gender'] = this.formData.gender
        payload['age'] = this.formData.age
        payload['experience'] = this.formData.experience
        payload['admin_verified'] = this.formData.admin_verified
      }

      const user = this.getCurrentUserData()

      if (this.$validate.DataValid(this.id)) {
        try {
          if (this.formData.user_type === 'student') {
            await this.$Fetcher.UpdateStudent(this.id, payload, user.id, user.token)
          } else if (this.formData.user_type === 'tutor') {
            await this.$Fetcher.UpdateTutor(this.id, payload, user.id, user.token)
          } else {
            throw '請選擇用戶類別'
          }

          this.$store.dispatch('setDataIsUpdated', false)
          this.$store.dispatch('toggleSnack', {
            type: 'success',
            message: '儲存成功',
            refresh: true,
          })
        } catch (err) {
          this.setAlert({
            title: '儲存失敗',
            message: err,
            type: 'error',
          })
        } finally {
          this.$store.dispatch('setLoading', false)
        }
      } else {
        try {
          if (this.formData.user_type === 'student') {
            await this.$Fetcher.NewStudent(payload, user.id, user.token)
          } else if (this.formData.user_type === 'tutor') {
            await this.$Fetcher.NewTutor(payload, user.id, user.token)
          } else {
            throw '請選擇用戶類別'
          }

          this.$store.dispatch('setDataIsUpdated', false)
          this.$store.dispatch('toggleSnack', {
            type: 'success',
            message: '新增成功',
            redirect: 'UserList',
          })
        } catch (err) {
          this.$common.error(err);
          this.setAlert({
            title: '新增失敗',
            message: err,
            type: 'error',
          })
        } finally {
          this.$store.dispatch('setLoading', false)
        }
      }
    },
  },
  async created() {
    this.$store.dispatch('setInitLoading', true)
    await this.getStudentGroupOptions();
    if (this.$validate.DataValid(this.id)) {
      this.getDetail()
    } else {
      this.$store.dispatch('setInitLoading', false)
    }
  },

  // ------ navigation guard ------
  beforeRouteLeave(to, from, next) {
    if (this.$store.getters.isLoading) {
      this.$store.dispatch('toggleSnack', {
        show: true,
        message: 'processing',
        type: 'error',
        refresh: false,
        redirect: '',
      })
      next(false)
    } else {
      next()
    }
  },
}
</script>
